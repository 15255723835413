<template>
    <v-navigation-drawer
        :value="isFiltersActive"
        style="z-index: 10"
        temporary
        touchless
        :right="!$vuetify.rtl"
        :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
        app
        @input="(val) => $emit('update:is-add-new-user-sidebar-active', val)">
        <v-card height="100%">
            <div class="drawer-header d-flex align-center mb-4">
                <span class="font-weight-semibold text-base text--primary">{{ $t('Filters') }}</span>
                <v-spacer></v-spacer>
                <v-btn color="primary" icon small @click="$emit('update:is-add-new-user-sidebar-active', false)">
                    <v-icon size="22">
                        {{ mdiClose }}
                    </v-icon>
                </v-btn>
            </div>

            <v-card-text>
                <v-btn @click="deleteFilters" expanded class="d-flex ml-auto text-capitalize" text color="error">
                    {{ $t('DeleteFiltes') }}
                    <v-icon right>
                        {{ mdiCloseBoxOutline }}
                    </v-icon>
                </v-btn>
                <div class="mt-4">
                    <v-autocomplete
                        v-model="query.filters.type.$containsi"
                        filled
                        :label="$t('Type')"
                        :items="routeType"
                        item-text="name"
                        item-value="value"
                        auto-select-first
                        chips
                        clearable
                        deletable-chips
                        multiple></v-autocomplete>

                    <!-- <v-autocomplete
                        v-model="query.filters.agency.name.$containsi"
                        filled
                        :search-input.sync="agencySearch"
                        @update:search-input="getAgencyName"
                        :label="$t('Agency')"
                        :items="agencys"
                        item-text="name"
                        item-value="name"
                        auto-select-first
                        chips
                        clearable
                        deletable-chips
                        multiple></v-autocomplete> -->

                    <!-- <v-range-slider
                        persistent-hint
                        min="0"
                        max="100"
                        v-model="query.filters.mobility_fare_rules.fare.price.$between"
                        thumb-label
                        :label="$t('Price')">
                    </v-range-slider> -->

                    <div class="mt-6">
                        <v-btn @click="applyFilters" color="primary" class="me-3" type="submit">
                            {{ $t('Buttons.Filter') }}
                        </v-btn>
                        <v-btn
                            @click="$emit('update:is-add-new-user-sidebar-active', false)"
                            color="secondary"
                            outlined>
                            {{ $t('Buttons.Cancel') }}
                        </v-btn>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-navigation-drawer>
</template>

<script>
    import { mdiClose, mdiCloseBoxOutline } from '@mdi/js';
    import { getAgencys } from '@/api/agency';
    import routeStoreModule from '@/views/route/routeStoreModule';

    export default {
        data() {
            return {
                routeStoreModule,
                routeType: [
                    { name: 'Tram', value: 'O0' },
                    { name: 'Subway', value: 'O1' },
                    { name: 'Rail', value: 'O2' },
                    { name: 'Bus', value: 'O3' },
                    { name: 'Ferry', value: 'O4' },
                    { name: 'CableTram', value: 'O5' },
                    { name: 'AerialLift', value: 'O6' },
                    { name: 'Funicular', value: 'O7' },
                    { name: 'Trolleybus', value: 'O11' },
                    { name: 'Monorail', value: 'O12' },
                ],
                query: {},
                agencys: [],
                agencySearch: '',
                debounce: null,
            };
        },
        model: {
            prop: 'isFiltersActive',
            event: 'update:is-add-new-user-sidebar-active',
        },
        props: {
            isFiltersActive: {
                type: Boolean,
                required: true,
            },
        },

        setup() {
            return {
                mdiClose,
                mdiCloseBoxOutline,
            };
        },
        created() {
            this.query = this.$store.getters['app-routes/getRoutesQuery'];

            this.getAgencyName();
            for (let index = 0; index < this.routeType.length; index++) {
                this.routeType[index].name = this.$t(this.routeType[index].name);
            }
            // this.query.filters.mobility_fare_rules.fare.price.$between = [0, 100];
        },
        methods: {
            applyFilters() {
                this.query.pagination.page = 1;
                this.$store.commit('app-routes/setRoutesQuery', this.query);
                this.$emit('setQuery');
                this.$emit('update:is-add-new-user-sidebar-active', false);
            },
            deleteFilters() {
                this.query.filters.type.$containsi = '';
                // this.query.filters.agency.name.$containsi = '';
                // this.agencySearch = '';
                // this.query.filters.mobility_fare_rules.fare.price.$between = [0, 100];
            },

            getAgencyName() {
                clearTimeout(this.debounce);
                this.debounce = setTimeout(() => {
                    let filter = {
                        name: {
                            $containsi: this.agencySearch,
                        },
                    };
                    getAgencys({ filters: filter })
                        .then((response) => {
                            this.agencys = response.data.data;
                        })
                        .catch((error) => {
                            throw error;
                        });
                }, 500);
            },
        },
    };
</script>
