import store from '@/store';
import _ from 'lodash';
import { i18n } from '@/plugins/i18n';
import { ref, watch } from '@vue/composition-api';

export default function useCalendarList() {
    const calendarDateListTable = ref([]);

    const calendarDateTableColumns = [
        { text: i18n.t('Date'), value: 'date', class: 'text-uppercase', width: '12rem' },
        { text: i18n.t('ExceptionType'), value: 'exception_type', class: 'text-uppercase', width: '12rem' },

        {
            text: '',
            value: 'actions',
            width: '5%',
        },
    ];

    const totalCalendarDateListTable = ref(calendarDateListTable.length);

    const oldSearch = ref('');
    const oldDateFilter = ref('');
    const oldExceptionTypeFilter = ref('');

    const fetchCalendarDateList = () => {
        let verifyQuery = store.getters['app-routes/getCalendarsDateQuery'];
        let isDeleteCalendarDate = store.getters['app-routes/getIsDeleteCalendarDate'];
        store
            .dispatch('app-routes/fetchCalendarDateList', {})
            .then((response) => {
                if (isDeleteCalendarDate) {
                    this.$store.commit('app-routes/setIsDeleteCalendarDate', true);
                }
                calendarDateListTable.value = [];
                calendarDateListTable.value.push(...response.data.data);
                totalCalendarDateListTable.value = response.data.meta.pagination.total;
            })
            .catch((error) => {
                throw error;
            });
    };

    return {
        calendarDateListTable,
        calendarDateTableColumns,
        totalCalendarDateListTable,
        fetchCalendarDateList,
    };
}
