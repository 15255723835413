<template>
    <section class="section">
        <v-sheet class="overflow-y-auto">
            <v-container>
                <div class="mt-6">
                    <div class="d-flex">
                        <!-- search -->
                        <v-text-field
                            expanded
                            v-model="search"
                            @change="searchStops()"
                            :placeholder="$t('Search')"
                            outlined
                            hide-details
                            dense
                            :prepend-inner-icon="icons.mdiMagnify"
                            class="user-search me-3 mb-4">
                        </v-text-field>
                        <v-btn @click="dialog = true" color="primary" class="ml-3">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                                {{ icons.mdiPlus }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="white">
                                {{ icons.mdiPlus }}
                            </v-icon>

                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('ImportStops') }}</span>
                        </v-btn>
                    </div>

                    <v-data-table
                        height="450"
                        calculate-widths
                        :items-per-page="25"
                        :footer-props="{
                            'items-per-page-options': [25],
                            'items-per-page-text': $t('RowsPerPage'),
                        }"
                        :header-props="{ 'sort-by-text': $t('SortBy') }"
                        :headers="tableColumns"
                        :items="items"
                        :server-items-length="totalItems"
                        :loading="isLoading"
                        :options.sync="options"
                        @update:page="paginationHandle"
                        fixed-header>
                    </v-data-table>
                </div>
            </v-container>
            <!-- dialog to import stops with a csv file, it should also have a button to download a template -->
            <v-dialog v-model="dialog" max-width="600px">
                <v-card :loading="isLoading">
                    <v-card-title>
                        <span class="headline">{{ $t('ImportStops') }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-btn color="primary" @click="downloadTemplate">{{ $t('DownloadTemplate') }}</v-btn>
                        <v-file-input
                            hide-details="auto"
                            class="my-4"
                            label="Select CSV File"
                            v-model="selectedFile"></v-file-input>
                        <v-progress-linear
                            v-if="importCount > 0"
                            :value="(importProgress / importCount) * 100"
                            color="primary"
                            height="24"
                            rounded
                            class="my-4">
                            <span>{{ importProgress }} / {{ importCount }}</span>
                        </v-progress-linear>
                        <!-- X imported of Y -->
                        <template v-if="hasImported">
                            <p class="my-0 py-0">
                                <span class="font-weight-bold">{{ $t('Imported') }}:</span> {{ importSuccess }}
                            </p>
                            <p class="my-0 py-0">
                                <span class="font-weight-bold">{{ $t('NotImported') }}:</span> {{ importErrors.length }}
                            </p>
                            <p class="my-0 py-0"><span class="font-weight-bold">Total:</span> {{ importCount }}</p>
                            <v-expansion-panels class="my-4" v-if="importErrors.length > 0">
                                <v-expansion-panel>
                                    <v-expansion-panel-header disable-icon-rotate>
                                        {{ $t('Errors') }}
                                        <template v-slot:actions>
                                            <v-icon color="error">{{ icons.mdiAlertCircle }}</v-icon>
                                        </template>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-alert
                                            type="error"
                                            border="left"
                                            colored-border
                                            elevation="0"
                                            icon="mdi-alert-circle-outline">
                                            <div v-for="error in importErrors" :key="error.line">
                                                <p class="font-weight-bold">Line {{ error.line }}:</p>
                                                <ul>
                                                    <li v-for="(err, i) in error.details" :key="i">
                                                        {{ err.message }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </v-alert>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </template>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn :disabled="isLoading" color="primary" @click="importStops">{{ $t('Import') }}</v-btn>
                        <v-btn color="error" @click="onCloseImport">{{ $t('Buttons.Cancel') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-sheet>
    </section>
</template>

<script>
    import { mdiMagnify, mdiPlus, mdiAlertCircle } from '@mdi/js';
    import axios from '@axios';
    import qs from 'qs';
    export default {
        name: 'StopsList',
        data() {
            return {
                search: '',
                dialog: false,
                selectedFile: null,
                isLoading: false,
                hasImported: false,
                totalItems: 0,
                options: {
                    page: 1,
                    itemsPerPage: 25,
                },
                items: [],
                icons: {
                    mdiMagnify,
                    mdiPlus,
                    mdiAlertCircle,
                },
                importCount: 0,
                importErrors: [],
                importSuccess: 0,
                importProgress: 0,
            };
        },
        created() {
            this.fetchStops();
        },
        computed: {
            tableColumns() {
                return [
                    { text: this.$t('Code'), class: 'text-uppercase', value: 'code', width: '6rem' },
                    { text: this.$t('Name'), value: 'name', class: 'text-uppercase', width: '12rem' },
                    // Location
                    { text: this.$t('Location'), class: 'text-uppercase', value: 'location.name', width: '16rem' },
                    // Desc
                    { text: this.$t('Description'), class: 'text-uppercase', value: 'desc' },
                ];
            },
        },
        methods: {
            async fetchStops() {
                this.isLoading = true;
                const query = {
                    populate: 'location',
                    filters: {
                        name: {
                            $containsi: this.search,
                        },
                    },
                    pagination: {
                        page: this.options.page,
                    },
                };
                const { data } = await axios.get(`/mobility-stops?${qs.stringify(query)}`);
                this.items = data.data;
                this.totalItems = data.meta.pagination.total;
                this.isLoading = false;
            },
            paginationHandle() {
                this.fetchStops();
            },
            searchStops() {
                this.options.page = 1;
                this.fetchStops();
            },
            downloadTemplate() {
                // get all keys in a stop
                const stop = this.items[0];
                const keys = Object.keys(stop);
                const keysToRemove = ['id', 'location', 'favoriteId', 'createdAt', 'updatedAt'];
                // remove the keys that we don't want to show in the template
                const filteredKeys = keys.filter((key) => !keysToRemove.includes(key));

                // create the csv headers
                const csvContent = filteredKeys.join(',') + ',\n';
                const blob = new Blob([csvContent], { type: 'text/csv' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'template.csv';
                a.click();
            },
            onCloseImport() {
                this.importCount = 0;
                this.importErrors = [];
                this.importSuccess = 0;
                this.importProgress = 0;
                this.hasImported = false;
                this.dialog = false;
                this.isLoading = false;
                this.options.page = 1;
                this.fetchStops();
            },
            importStops() {
                this.isLoading = true;
                this.importCount = 0;
                this.importErrors = [];
                this.importSuccess = 0;
                this.importProgress = 0;
                this.hasImported = false;
                // read the file
                const reader = new FileReader();
                reader.onload = async (e) => {
                    const text = e.target.result;
                    const rows = text.split('\n');
                    const headers = rows[0].split(',');
                    const stops = [];
                    // loop through the rows
                    for (let i = 1; i < rows.length; i++) {
                        const row = rows[i];
                        const stop = {};
                        const values = row.split(',');
                        // loop through the headers
                        for (let j = 0; j < headers.length; j++) {
                            const header = headers[j];
                            const value = values[j];
                            stop[header] = value;
                        }
                        stops.push(stop);
                    }
                    this.importCount = stops.length;
                    for (let i = 0; i < stops.length; i++) {
                        if (!this.isLoading) break;
                        const stop = stops[i];
                        try {
                            await axios.post('/mobility-stops', { data: stop });
                            this.importSuccess++;
                        } catch (error) {
                            this.importErrors.push({ line: i + 1, details: error.response.data.error.details.errors });
                        }

                        this.importProgress = i + 1;
                    }
                    this.hasImported = true;
                    // this.dialog = false;
                    this.isLoading = false;
                    // this.fetchStops();
                };
                reader.readAsText(this.selectedFile);
            },
        },
    };
</script>

<style lang="scss" scoped></style>
