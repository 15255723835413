<template>
    <section class="section">
        <v-sheet id="scrolling-techniques-7" class="overflow-y-auto">
            <v-container class="mt-10">
                <div id="routes-list" class="mt-8">
                    <div class="d-flex">
                        <!-- search -->
                        <v-text-field
                            expanded
                            v-model="search"
                            @change="searchRoutes()"
                            :placeholder="$t('Search')"
                            outlined
                            hide-details
                            dense
                            :prepend-inner-icon="icons.mdiMagnify"
                            class="user-search me-3 mb-4">
                        </v-text-field>

                        <v-btn @click.stop="getRouteFilter" outlined color="grey-light">
                            <v-icon color="grey">
                                {{ icons.mdiFilterVariant }}
                            </v-icon>
                        </v-btn>
                    </div>

                    <v-data-table
                        height="450"
                        show-select
                        calculate-widths
                        :items-per-page="25"
                        :footer-props="{
                            'items-per-page-options': [25],
                            'items-per-page-text': $t('RowsPerPage'),
                        }"
                        :header-props="{ 'sort-by-text': $t('SortBy') }"
                        :headers="tableColumns"
                        :items="routeListTable"
                        :server-items-length="totalRouteListTable"
                        :loading="isLoading"
                        v-model="selectedRoutes"
                        :options.sync="options"
                        @input="getRoutesSelected"
                        @update:page="paginationHandle"
                        fixed-header>
                        <template #[`item.long_name`]="{ item }">
                            <div class="extend-column d-flex flex-column ms-3">
                                <router-link
                                    style="max-width: 12rem"
                                    :to="{ name: 'RouteProfile', params: { id: item.id } }"
                                    class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none">
                                    {{ item.long_name }}
                                </router-link>
                            </div>
                        </template>
                        <template #[`item.type`]="{ item }">
                            <span> {{ item.type }} - {{ getRouteTypeName(item.type) }} </span>
                        </template>

                        <template #[`item.actions`]="{ item }">
                            <v-btn @click="$router.push({ name: 'RouteProfile', params: { id: item.id } })" icon>
                                <v-icon large>{{ icons.mdiChevronRight }}</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </div>
            </v-container>
        </v-sheet>
    </section>
</template>

<script>
    import {
        mdiAccountOutline,
        mdiChevronRight,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiExportVariant,
        mdiFileDocumentOutline,
        mdiFilterVariant,
        mdiMagnify,
        mdiPlus,
        mdiSquareEditOutline,
        mdiTrashCanOutline,
    } from '@mdi/js';
    import useRoutesList from './useRoutesList';
    import RouteFilters from '../route-filters/RouteFilters.vue';
    export default {
        props: {
            routesDeletedFinished: {
                type: Boolean,
            },
            fetchFilteredRoutes: {
                type: Boolean,
            },
        },
        components: {
            RouteFilters,
        },
        setup() {
            const { fetchRoutesList, tableColumns, routeListTable, totalRouteListTable } = useRoutesList();

            return {
                tableColumns,
                routeListTable,
                totalRouteListTable,
                fetchRoutesList,

                // icons
                icons: {
                    mdiSquareEditOutline,
                    mdiFileDocumentOutline,
                    mdiDotsVertical,
                    mdiDeleteOutline,
                    mdiPlus,
                    mdiExportVariant,
                    mdiAccountOutline,
                    mdiMagnify,
                    mdiChevronRight,
                    mdiFilterVariant,
                    mdiTrashCanOutline,
                },
            };
        },

        data() {
            return {
                search: null,
                selectedRoutes: [],

                isLoading: false,
                isSuccess: false,
                isError: false,
                query: {},
                options: {
                    page: 1,
                    itemsPerPage: 25,
                },
            };
        },

        methods: {
            searchRoutes() {
                this.isLoading = true;
                this.$store.commit('app-routes/setRouteSearch', this.search);
                this.query = this.$store.getters['app-routes/getRoutesQuery'];
                this.query.pagination.page = 1;
                this.fetchRoutesList();
                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            },

            getRouteTypeName(type) {
                switch (type) {
                    case 'O0':
                        return this.$t('Tram');
                    case 'O1':
                        return this.$t('Subway');
                    case 'O2':
                        return this.$t('Rail');
                    case 'O3':
                        return this.$t('Bus');
                    case 'O4':
                        return this.$t('Ferry');
                    case 'O5':
                        return this.$t('CableTram');
                    case 'O6':
                        return this.$t('AerialLift');
                    case 'O7':
                        return this.$t('Funicular');
                    case 'O11':
                        return this.$t('Trolleybus');
                    case 'O12':
                        return this.$t('Monorail');

                    default:
                        break;
                }
            },

            getRoutesSelected() {
                this.$emit('getRoutesSelected', this.selectedRoutes);
            },

            getRouteFilter() {
                this.$emit('getRouteFilter', true);
            },

            paginationHandle(e) {
                if (this.options.page > this.query.pagination.page) {
                    this.query.pagination.page = this.options.page;
                    this.$store.commit('app-routes/setRoutesQuery', this.query);
                    this.fetchRoutesList();
                }
            },

            getFare(fare) {
                if (fare[0]?.fare) {
                    return fare[0].fare.price + ' €';
                }
            },
        },

        computed: {
            fetchList: {
                get() {
                    return this.fetchList;
                },
                set(value) {
                    this.fetchList = value;
                },
            },
        },

        created() {
            this.isLoading = true;
            this.$store.commit('app-routes/resetRoutesQuery');
            this.query = this.$store.getters['app-routes/getRoutesQuery'];
            this.fetchRoutesList();
            setTimeout(() => {
                this.isLoading = false;
            }, 3000);
        },

        watch: {
            routesDeletedFinished: function (newVal, oldVal) {
                if (newVal == true) {
                    this.fetchRoutesList();
                }
            },

            fetchFilteredRoutes: function (newVal, oldVal) {
                if (newVal == true) {
                    this.isLoading = true;
                    this.fetchRoutesList();
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 3000);
                }
            },
        },

        mounted() {
            this.$emit('getMainActiveTab', this.$route.params.tab);
        },
    };
</script>

<style lang="scss">
    @import '@core/preset/preset/apps/user.scss';
    @import '@/styles/variables.scss';
    progress-bar {
        background-color: #58585836;
    }
    .trash-icon {
        width: 60rem;
        height: 60rem;
    }
</style>
