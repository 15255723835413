var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"section"},[_c('v-sheet',{staticClass:"overflow-y-auto",attrs:{"id":"scrolling-techniques-7"}},[_c('v-container',{staticClass:"mt-10"},[_c('div',{staticClass:"mt-8",attrs:{"id":"routes-list"}},[_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"user-search me-3 mb-4",attrs:{"expanded":"","placeholder":_vm.$t('Search'),"outlined":"","hide-details":"","dense":"","prepend-inner-icon":_vm.icons.mdiMagnify},on:{"change":function($event){return _vm.searchRoutes()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{attrs:{"outlined":"","color":"grey-light"},on:{"click":function($event){$event.stopPropagation();return _vm.getRouteFilter.apply(null, arguments)}}},[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilterVariant)+" ")])],1)],1),_c('v-data-table',{attrs:{"height":"450","show-select":"","calculate-widths":"","items-per-page":25,"footer-props":{
                        'items-per-page-options': [25],
                        'items-per-page-text': _vm.$t('RowsPerPage'),
                    },"header-props":{ 'sort-by-text': _vm.$t('SortBy') },"headers":_vm.tableColumns,"items":_vm.routeListTable,"server-items-length":_vm.totalRouteListTable,"loading":_vm.isLoading,"options":_vm.options,"fixed-header":""},on:{"update:options":function($event){_vm.options=$event},"input":_vm.getRoutesSelected,"update:page":_vm.paginationHandle},scopedSlots:_vm._u([{key:"item.long_name",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"extend-column d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",staticStyle:{"max-width":"12rem"},attrs:{"to":{ name: 'RouteProfile', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.long_name)+" ")])],1)]}},{key:"item.type",fn:function(ref){
                    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.type)+" - "+_vm._s(_vm.getRouteTypeName(item.type))+" ")])]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({ name: 'RouteProfile', params: { id: item.id } })}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(_vm.icons.mdiChevronRight))])],1)]}}],null,true),model:{value:(_vm.selectedRoutes),callback:function ($$v) {_vm.selectedRoutes=$$v},expression:"selectedRoutes"}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }