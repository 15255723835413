var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"overflow-y-auto",attrs:{"id":"scrolling-techniques-7"}},[_c('v-container',[_c('v-tabs',{attrs:{"center-active":""},on:{"change":_vm.getTripsSelectedTab},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab',[_vm._v(_vm._s(_vm.$t('TripsList')))]),_c('v-tab',[_vm._v(" "+_vm._s(_vm.$t('SchedulesList')))]),_c('v-tab',[_vm._v(_vm._s(_vm.$t('Calendar')))]),_c('v-tab-item',[_c('v-container',{staticClass:"mt-4"},[_c('div',{staticClass:"mt-8",attrs:{"id":"routes-list"}},[_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"user-search me-3 mb-4",attrs:{"expanded":"","placeholder":_vm.$t('Search'),"outlined":"","hide-details":"","dense":"","prepend-inner-icon":_vm.icons.mdiMagnify},on:{"change":function($event){return _vm.searchTrips()}},model:{value:(_vm.searchTripsQuery),callback:function ($$v) {_vm.searchTripsQuery=$$v},expression:"searchTripsQuery"}}),_c('v-btn',{attrs:{"outlined":"","color":"grey-light"},on:{"click":function($event){$event.stopPropagation();return _vm.getTripFilter.apply(null, arguments)}}},[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilterVariant)+" ")])],1)],1),_c('v-data-table',{attrs:{"show-select":"","calculate-widths":"","items-per-page":25,"footer-props":{
                                'items-per-page-options': [25],
                                'items-per-page-text': _vm.$t('RowsPerPage'),
                            },"header-props":{ 'sort-by-text': _vm.$t('SortBy') },"headers":_vm.tripsTableColumns,"items":_vm.tripListTable,"server-items-length":_vm.totalTripListTable,"loading":_vm.isLoading,"options":_vm.tripOptions,"height":"400","fixed-header":""},on:{"input":_vm.getTripsSelected,"update:page":_vm.tripPaginationHandle,"update:options":function($event){_vm.tripOptions=$event}},scopedSlots:_vm._u([{key:"item.short_name",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"extend-column d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",staticStyle:{"max-width":"12rem"},attrs:{"to":{ name: 'TripProfile', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.short_name)+" ")])],1)]}},{key:"item.bikes_allowed",fn:function(ref){
                            var item = ref.item;
return [_c('span',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(item.bikes_allowed)+" - "+_vm._s(_vm.getBikesInformation(item.bikes_allowed))+" ")])]}},{key:"item.wheelchair_accessible",fn:function(ref){
                            var item = ref.item;
return [_c('span',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(item.wheelchair_accessible)+" - "+_vm._s(_vm.getWheelchairInformation(item.wheelchair_accessible))+" ")])]}},{key:"item.actions",fn:function(ref){
                            var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({ name: 'TripProfile', params: { id: item.id } })}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(_vm.icons.mdiChevronRight))])],1)]}}],null,true),model:{value:(_vm.selectedTrips),callback:function ($$v) {_vm.selectedTrips=$$v},expression:"selectedTrips"}})],1)])],1),_c('v-tab-item',[_c('v-container',{staticClass:"mt-4"},[_c('div',{staticClass:"mt-8",attrs:{"id":"routes-list"}},[_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"user-search me-3 mb-4",attrs:{"expanded":"","placeholder":_vm.$t('Search'),"outlined":"","hide-details":"","dense":"","prepend-inner-icon":_vm.icons.mdiMagnify},on:{"change":function($event){return _vm.searchStopTimes()}},model:{value:(_vm.searchStopTimesQuery),callback:function ($$v) {_vm.searchStopTimesQuery=$$v},expression:"searchStopTimesQuery"}}),_c('v-btn',{attrs:{"outlined":"","color":"grey-light"},on:{"click":function($event){$event.stopPropagation();return _vm.getStopTimeFilter.apply(null, arguments)}}},[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilterVariant)+" ")])],1)],1),_c('v-data-table',{attrs:{"show-select":"","calculate-widths":"","items-per-page":25,"footer-props":{
                                'items-per-page-options': [25],
                                'items-per-page-text': _vm.$t('RowsPerPage'),
                            },"header-props":{ 'sort-by-text': _vm.$t('SortBy') },"headers":_vm.stopsTableColumns,"items":_vm.stopListTable,"server-items-length":_vm.totalStopListTable,"options":_vm.stopTimesOptions,"loading":_vm.isLoading,"fixed-header":"","height":"400"},on:{"input":_vm.getStopTimesSelected,"update:page":_vm.stopTimesPaginationHandle,"update:options":function($event){_vm.stopTimesOptions=$event}},scopedSlots:_vm._u([{key:"item.departure_time",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"extend-column d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",staticStyle:{"max-width":"12rem"},attrs:{"to":{ name: 'StopTimesProfile', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.departure_time)+" ")])],1)]}},{key:"item.arrival_time",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"extend-column d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",staticStyle:{"max-width":"12rem"},attrs:{"to":{ name: 'StopTimesProfile', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.arrival_time)+" ")])],1)]}},{key:"item.stop.name",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"extend-column d-flex flex-column ms-3"},[_c('span',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(item.stop.name)+" "),(item.stop.city)?_c('span',[_vm._v("("+_vm._s(item.stop.city)+")")]):_vm._e()])])]}},{key:"item.pickup_type",fn:function(ref){
                            var item = ref.item;
return [_c('span',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(item.pickup_type)+" - "+_vm._s(_vm.getPickUpInformation(item.pickup_type))+" ")])]}},{key:"item.drop_off_type",fn:function(ref){
                            var item = ref.item;
return [_c('span',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(item.drop_off_type)+" - "+_vm._s(_vm.getDropOffInformation(item.drop_off_type))+" ")])]}},{key:"item.actions",fn:function(ref){
                            var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({ name: 'StopTimesProfile', params: { id: item.id } })}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(_vm.icons.mdiChevronRight))])],1)]}}],null,true),model:{value:(_vm.selectedStopTimes),callback:function ($$v) {_vm.selectedStopTimes=$$v},expression:"selectedStopTimes"}})],1)])],1),_c('v-tab-item',[_c('CalendarList',{attrs:{"calendarsDeletedFinished":_vm.calendarsDeletedFinished,"fetchFilteredCalendars":_vm.fetchFilteredCalendars,"calendarsDateDeletedFinished":_vm.calendarsDateDeletedFinished,"fetchFilteredCalendarDates":_vm.fetchFilteredCalendarDates,"calendarActiveTab":_vm.calendarActiveTab},on:{"getCalendarsSelected":_vm.getCalendarsSelected,"getCalendarFilter":_vm.getCalendarFilter,"getCalendarSelectedTab":_vm.getCalendarSelectedTab,"getCalendarDatesSelected":_vm.getCalendarDatesSelected,"getCalendarDatesFilter":_vm.getCalendarDatesFilter}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }