import store from '@/store';
import _ from 'lodash';
import { i18n } from '@/plugins/i18n';
import { ref, watch } from '@vue/composition-api';

export default function useStopTimesList() {
    const stopListTable = ref([]);

    const stopsTableColumns = [
        { text: i18n.t('Arrival'), value: 'arrival_time', class: 'text-uppercase', width: '8rem' },
        { text: i18n.t('Departure'), value: 'departure_time', class: 'text-uppercase', width: '8rem' },
        { text: i18n.t('Stop'), value: 'stop.name', class: 'text-uppercase', width: '12rem' },
        { text: i18n.t('Headsign'), value: 'headsign', class: 'text-uppercase', width: '12rem' },
        { text: i18n.t('PickupType'), value: 'pickup_type', class: 'text-uppercase', width: '12rem' },
        { text: i18n.t('DropOffType'), value: 'drop_off_type', class: 'text-uppercase', width: '12rem' },

        {
            text: '',
            value: 'actions',
            width: '5%',
        },
    ];

    const totalStopListTable = ref(stopListTable.length);
    const oldSearch = ref('');
    const oldDepartureFilter = ref('');
    const oldArrivalFilter = ref('');
    const oldStopFilter = ref('');
    const oldPickupTypeFilter = ref('');
    const oldDropOffTypeFilter = ref('');

    const fecthStopTimesList = () => {
        let verifyQuery = store.getters['app-routes/getStopTimesQuery'];
        let isDeleteStopTime = store.getters['app-routes/getIsDeleteStopTimes'];
        store
            .dispatch('app-routes/fetchStopTimesList')
            .then((response) => {
                if (isDeleteStopTime) {
                    store.commit('app-routes/setIsDeleteStopTimes', true);
                }

                stopListTable.value = [];
                stopListTable.value.push(...response.data.data);
                totalStopListTable.value = response.data.meta.pagination.total;
            })
            .catch((error) => {
                throw error;
            });
    };

    return {
        stopsTableColumns,
        stopListTable,
        totalStopListTable,
        fecthStopTimesList,
    };
}
